/* Main container styling */
.matches-table-container {
    text-align: center; /* Center text and inline elements */
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    max-width: 1200px; /* Adjust as needed */
}

/* Heading styling */
.matches-table-container h2 {
    font-size: 2rem;
    color: #333333;
    margin-bottom: 20px;
}

/* Table styling */
.matches-table {
    width: 70%;
    margin: 0 auto; /* Center table horizontally */
    border-collapse: collapse;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.matches-table th,
.matches-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
}

.matches-table th {
    background-color: #f5f5f5;
    font-weight: bold;
    color: #555555;
}

.matches-table tbody tr:nth-child(even) {
    background-color: #fafafa;
}

.matches-table tbody tr:hover {
    background-color: #f0f0f0;
}

/* Add padding to cells in the "Match Count" column */
.matches-table td:nth-child(1),
.matches-table th:nth-child(1) {
    padding-left: 25px; /* Adjust as needed */
}

/* Loading and error styling */
.matches-table-container.loading,
.matches-table-container.error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 1.2rem;
    color: #555555;
}

.matches-table-container.error {
    color: #ff6347; /* Tomato */
}

/* Load more button container styling */
.load-more-container {
    margin-top: 20px;
}

.load-more-container button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #ffffff;
    background-color: #333333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.load-more-container button:hover {
    background-color: #555555;
}

/* Responsive styling */
@media screen and (max-width: 768px) {
    .matches-table {
        width: 100%; /* Full width on smaller screens */
        box-shadow: none;
        border-radius: 0;
    }

    .matches-table th,
    .matches-table td {
        padding: 10px;
    }

    .matches-table-container {
        padding: 10px;
    }
}


.no-matches-message {
    font-size: 1.2rem;
    color: #555;
    text-align: center;
    margin: 20px;
}

.default-cover-image {
    margin-top: 20px;
    max-width: 25%;
    height: auto;
}
