.home-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  height: auto; /* Adjust height as needed */
}

.text-container {
  text-align: center;
  margin-bottom: 10px; /* Add some space between the text and the image */
}

.text-container p {
  margin: 0; /* Remove default margins from paragraphs */
  padding: 5px 0; /* Add some padding for spacing */
  color:#333;
}

.centered-image {
  max-width:37%;
  max-height: 37%;
  margin-top: 18px;
  margin-bottom: 18px;
  border-radius: 5px;
}

.text-container a:hover {
  text-decoration: underline; /* Underline the link on hover */
}

.register-link-container {
  margin-top: 20px;
  text-align: center;
}

.all-matches-link-container {
  text-align: center;
  margin-top: 20px;
}

.all-matches-link-container a {
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
}

.all-matches-link-container a:hover {
  text-decoration: underline;
}

/* Placeholder for when user is not authenticated */
.matches-placeholder {
  text-align: center;
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.matches-placeholder h2 {
  font-size: 24px;
  color: #555;
  margin-bottom: 10px;
}

.matches-placeholder p {
  font-size: 16px;
  color: #777;
}

.text-container a {
  color: #0071c5; /* Blue color for the link */
  text-decoration: underline; /* Remove underline from the link */
}

.text-container h3 {
  color: #444; /* Dark color for the heading */
  text-decoration: underline; /* Underline the heading */
}

.text-container a:hover {
  text-decoration: underline; /* Underline the link on hover */
}

@media only screen and (max-width: 600px) {
  .centered-image {
    max-width: 65%; 
    max-height: auto; 
  }
}
