/* Container for the reset password form */
.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the start (top) */
  height: 100vh; /* Full viewport height */
  background-color: #f5f5f5;
  padding-top: 25vh; /* Center the form halfway up the blank space */
}

/* Reset password form styles */
.reset-password {
  max-width: 400px;
  width: 100%; /* Ensure the form uses the full width of its container */
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Include padding and border in the width */
}

/* Header styles */
.reset-password h1 {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
  text-align: center;
}

/* Input styles */
.reset-password-input {
  width: 100%; /* Make all inputs use full width of their container */
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

/* Placeholder text styles */
.reset-password-input::placeholder {
  color: #666;
}

/* Button styles */
.reset-password-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #d13525;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.reset-password-button:hover {
  background-color: #b72f20;
}

/* Optional: Display message styles */
.reset-password p {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

/* Optional: Form level error or message styles */
.reset-password .error-message {
  color: #F70025;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

/* Styles for the password input container */
.input-container {
  position: relative;
}

/* Adjusted styles for password inputs to match width with other inputs */
.input-container .reset-password-input {
  width: 100%; /* Ensure all inputs are the same width */
  padding-right: 40px; /* Space for the eye icon */
}

/* Toggle password icon styles */
.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666; /* Adjust color as needed */
  font-size: 18px; /* Adjust size as needed */
}
