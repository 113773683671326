.profile-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}


.profile-container button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 33px;
    border: 2px solid #808080;
    background-color: #808080;
    color: white;
    margin-top: 17px;
}

.profile-container button:hover {
    background-color: #778899;
    border: 2px solid #778899
}

.profile-container h1 {
  color : #333;
}

.profile-container h2 {
  color : #333;
}

.profile-container h3 {
  color : #1e1e20;
}
.profile-container h4 {
  color : #444;
}

/* Header styles */
.profile-header {
  margin-bottom: 20px;
}

.profile-header h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333333;
}

.profile-header p {
  font-size: 16px;
  margin-bottom: 5px;
  color: #666666;
}

/* My Reviews section */
.my-reviews {
  margin-top: 30px;
}

.my-reviews h1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333333;
}

.my-reviews p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555555;
}

.my-reviews ul {
  list-style-type: none;
  padding: 0;
}

.my-reviews li {
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  padding: 15px;
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.my-reviews li p {
  margin: 5px 0;
  color: #333;
}



/* Loading spinner */
.loading {
  font-size: 18px;
  text-align: center;
  margin-top: 50px;
  color: #888888;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .profile-container {
    padding: 15px;
  }

  .my-reviews li {
    padding: 12px;
  }
}

.star-rating {
  color: #962715; /* Replace with your desired hex color */
}

.my-completed-books {
  margin-top: 30px;
  color: #555
}

.my-completed-books h1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333333;
}

.my-completed-books p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555555;
}

.my-completed-books ul {
  list-style-type: none;
  padding: 0;
}

.my-completed-books li {
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  padding: 15px;
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.my-completed-books li p {
  margin: 5px 0;
  color: #666666;
}

.my-completed-books ul li a {
  color: #d13525;
  text-decoration: none;
}

.my-completed-books ul li a:hover {
  color: #a5281b;
  text-decoration: underline;
}

.my-wishlist ul li a {
  color: #0071c5;
  text-decoration: none;
}

.my-wishlist ul li a:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* My Wishlist section */
.my-wishlist {
margin-top: 30px;
color: #555;
}

.my-wishlist h3 {
font-size: 20px;
font-weight: bold;
margin-bottom: 15px;
color: #333333;
}

.my-wishlist p {
font-size: 16px;
margin-bottom: 10px;
color: #555555;
}

.my-wishlist ul {
list-style-type: none;
padding: 0;
}

.my-wishlist li {
margin-bottom: 20px;
border: 1px solid #e0e0e0;
padding: 15px;
border-radius: 8px;
background-color: #fafafa;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.my-wishlist li p {
margin: 5px 0;
color: #666666;
}

/* Responsive adjustments for wishlist */
@media (max-width: 768px) {
.my-wishlist li {
  padding: 12px;
}
}

/* Modal styles */
.modal {
display: flex;
align-items: center;
justify-content: center;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5);
}

.modal-content {
background: #fff;
padding: 20px;
border-radius: 5px;
position: relative;
width: 80%;
max-width: 600px;
}

.close {
position: absolute;
top: 10px;
right: 10px;
font-size: 24px;
cursor: pointer;
}

.rating-container {
display: flex;
margin-bottom: 10px;
}

.star {
font-size: 22px;
color: #ddd;
cursor: pointer;
}

.star-filled {
color: #ffd700;
font-size: 22px;
}

.review-text {
width: 100%;
height: 100px;
}

.character-count {
text-align: right;
color: #777;
font-size: 14px;
}

.submit-review-btn {
background-color: #d13525;
color: #fff;
border: none;
padding: 10px 20px;
cursor: pointer;
border-radius: 4px;
font-size: 16px;
}



.check-box-note {
  font-size: 16px;
  color: #555;
  margin-top: 10px;
}

.found-label {
  float: right;
  color: green;
  font-weight: bold;
}

.check-box-logo {
  width: 18px;
  height: 18px;
  margin-left: 8px;
  vertical-align: middle;
}

.my-reviews .review-link {
  color: #555;
  text-decoration: none;
}

.my-reviews .review-link a{
  color: #222;
  text-decoration: none;
}

.my-reviews .review-link a:hover{
  color: #d13525;
  text-decoration: underline;
}

.review-item p {
  white-space: pre-wrap;
}

.review-item small {
  color: #666;
  text-size-adjust: none; 
}

.review-date {
  font-size: 14px !important;
  color: #666666 !important;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.mini_void {
  height: 1.5vh;
}

.user-profile-pic {
  width: auto;
  height: 100px;
  border-radius: 10%;
  margin-right: 20px;
  margin-left: 60px;
}

.profile-header .pic-upload-button {
  margin-left: 60px;
  margin-top: -5px;
  margin-bottom: 10px;

}

