/* Main container styling */
.matches-table-container {
    margin-top: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.matches-table-container.unauthenticated {
    height: vh; /* Adjust to make the container smaller when not authenticated */
}

/* Loading and error styling */
.matches-table-container.loading,
.matches-table-container.error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 1.2rem;
    color: #555555;
}

.matches-table-container.error {
    color: #ff6347; /* Tomato */
}

/* Custom heading styling */
.matches-table-container .custom-h2 {
    font-size: 1.75rem; /* Size between h2 and h3 */
    color: #333333;
    margin-bottom: 20px;
}

/* Table styling */
.matches-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.matches-table th,
.matches-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
}

.matches-table th {
    background-color: #f5f5f5;
    font-weight: bold;
    color: #555555;
}

.matches-table a {
    color: #007bff; /* Bootstrap primary color, adjust as needed */
    text-decoration: none;
}

.matches-table a:hover {
    text-decoration: underline;
}

.matches-table a:visited {
    color: #d13525; /* Adjust to a different color for visited links */
}

.matches-table tbody tr:nth-child(even) {
    background-color: #fafafa;
}

.matches-table tbody tr:hover {
    background-color: #f0f0f0;
}

/* Responsive styling */
@media screen and (max-width: 768px) {
    .matches-table {
        box-shadow: none;
        border-radius: 0;
    }

    .matches-table th,
    .matches-table td {
        padding: 10px;
    }

    .matches-table-container {
        padding: 10px;
    }
}

.all-matches-link-container {
    margin-top: 20px;
    text-align: center;
}

.all-matches-link-container a {
    font-size: 1.2rem;
    color: #007bff; /* Bootstrap primary color, adjust as needed */
    text-decoration: none;
}

.all-matches-link-container a:hover {
    text-decoration: underline;
    color: #0056b3; /* Darker shade for hover effect */
}