/* Existing styles */
.login {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.login h1 {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
    text-align: center;
}

.input-container {
    position: relative;
}

.login-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensure padding and border are included in width */
}

.toggle-password {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    color: #888; /* Adjust color as needed */
    font-size: 18px; /* Adjust size as needed */
}

.login button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #d13525;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login button:hover {
    background-color: #b72f20;
}

/* Optional: Form level error or message styles */
.login .error-message {
    color: #dc3545;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}

/* Big void block to fill space */
.big_void {
    height: 25vh; /* Adjust this value as needed */
}

.small_void {
    height: 5vh; /* Adjust this value as needed */
}

.medium_void {
    height: 12vh; /* Adjust this value as needed */
}

.login-note {
    font-size: 15px;
    color: #666666;
    text-align: center;
    margin-top: 20px;
}

.login-note a {
    color: #0071c5; /* Blue color for the link */
    text-decoration: none; /* Remove underline from the link */
}

.login-note a:hover {
    text-decoration: underline;
    color: #555; /* Underline the link on hover */
}
