.footer {
  background-color: #f4f4ef;
  padding: 30px 20px;
  border-top: 1px solid #dee2e6;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  /* max-width: 1200px; */
  margin: 0 auto;
  width: 100%;
}

.footer-section {
  flex: 1;
  margin-right: 20px;
}

.footer-section:last-child {
  margin-right: 0;
}

.footer-section h4 {
  margin-bottom: 15px;
  font-size: 1.25rem;
  color: #444;
}

.footer-section p {
  font-size: 0.875rem;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  text-decoration: none;
  color: #0071c5;
  transition: color 0.3s;
}

.footer-section ul li a:hover {
  color: #0056b3;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons a {
  color: #495057;
  font-size: 1.25rem;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #0071c5;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  font-size: 0.875rem;
}

.footer-image-container {
  text-align: center; /* Centers the image container */
  margin: 20px 0; /* Adds margin above and below the container */
}

.footer-cover-image {
  width: 120px; /* Adjust the width as needed */
  height: auto; /* Maintains the aspect ratio */
}
