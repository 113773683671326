header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  background-color: #1e1e20;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  width: auto;
  
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

nav ul li {
  display: inline;
  display: inline-flex;
  align-items: center;
}

nav ul li a,
nav ul li button {
  text-decoration: none;
  color: #e3dac9;
  padding: 10px 15px;
  border: none;
  background: none;
  cursor: pointer;
}

nav ul li button:hover,
nav ul li a:hover {
  color: #007bff;
}

nav ul li a.read-link:hover {
  color: #ff0000;
}

.btn {
  background-color: #696969;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.btn:hover {
  background-color: #708090;
  color: white;
}

.hamburger {
  display: none;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #e3dac9; /* Ensure the hamburger icon is white */
}

nav {
  display: flex;
}

/* Logo styling */
.logo {
  width: 92%;
  max-width: 450px; /* Three times the base size */
}

.logo:hover {
  opacity: 0.75; /* Change opacity to 70% on hover */
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #1e1e20;
    border-radius: 8px;
    padding: 10px;
  }

  nav.open {
    display: flex;
  }

  nav ul {
    flex-direction: column;
    gap: 10px;
  }

  nav ul li {
    text-align: center;
  }

  .logo {
    width: 100%;
    min-width: 120px;
    max-width: 250px;
  }
}

header {
  position: relative; /* Ensure positioning context */
  z-index: 1000; /* High value to place it above other elements */
}