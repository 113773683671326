/* Container for the registration form */
.register {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

/* Header styles */
.register h1 {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
  text-align: center;
}

/* Input styles */
.register input {
  width: 100%; /* Make all inputs use full width of their container */
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

/* Placeholder text styles */
.register input::placeholder {
  color: #666;
}

/* Button styles */
.register button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #d13525;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.register button:hover {
  background-color: #b72f20;
}

/* Optional: Display user ID styles */
.register p {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

/* Optional: Form level error or message styles */
.register .error-message {
  color: #F70025;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.big_void {
  height: 25vh; /* Adjust this value as needed */
}

/* Adjusted styles for password inputs to match width with other inputs */
.password-input {
  position: relative;
}

.password-input input {
  width: 100%; /* Ensure all inputs are the same width */
  padding-right: 40px; /* Space for the eye icon */
}

/* Toggle password icon styles */
.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  color: #333; /* Adjust color as needed */
  font-size: 18px; /* Adjust size as needed */
}
