/* Book Detail Page Container */
.book-detail-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.book-detail-container small {
  color : #666;
}

.book-detail-container p {
  color : #333;
}

.book-detail-container h4 {
  color : #333;
}

.book-detail-container strong {
    color : #333;
  }


/* Loading and Error states */
.book-detail-container.loading,
.book-detail-container.error {
  text-align: center;
  font-size: 18px;
  color: #888888;
}

/* Header styles */
.book-detail-container h1 {
  font-size: 30px;
  font-weight: bold;
  color: #555;
  margin-bottom: 20px;
  text-align: center;
}

/* Book info container */
.book-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Book cover styles */
.book-details-cover {
  width: 155px;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Button container styling */
.book-actions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

/* Button styling */
.book-actions button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin: 5px;
}

/* "Add to Wishlist" button styling */
.book-actions .add-to-wishlist {
  background-color: #d13525;
  color: white;
  border: 2px solid #d13525;
}

.book-actions .add-to-wishlist:hover {
  background-color: #a5281b;
}

/* "Add to Completed" button styling */
.book-actions .add-to-completed {
  background-color: white;
  color: #d13525;
  border: 2px solid #d52513;
}

.book-actions .add-to-completed:hover {
  background-color: #f2f2f2;
  color: #a5281b;
}

/* Loading message styling */
.book-detail-container.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Error message styling */
.book-detail-container.error {
  color: red;
  text-align: center;
}

/* Star rating styles */
.star-rating {
  font-size: 20px;
  color: #f39c12;
}

.star-rating span {
  margin-right: 5px;
}


/* My Reviews section */
.reviews-section {
  margin-top: 30px;
}

.reviews-section h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333333;
  text-align: center;
}

.reviews-section p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555555;
}

.reviews-sectionul {
  list-style-type: none;
  padding: 0;
}

.reviews-section li {
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  padding: 25px;
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reviews-section li p {
  margin: 5px 0;
  color: #333;
}



/* Loading spinner */
.loading {
  font-size: 18px;
  text-align: center;
  margin-top: 50px;
  color: #888888;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .profile-container {
    padding: 15px;
  }
 
}

.reviews-section ul {
  list-style-type: none;
  padding: 0;
}

.average-rating {
  font-size: 20px;
  color: #333;
  text-align: center;
}

.average-rating {
  
  align-items: center;
  margin-bottom: 10px;
}

.average-rating-number {
  font-size: 1.1rem;
  color: #333;
  text-align: center;
  font-weight: bold;
}

.rating-count {
  font-size: 1rem;
  color: #555;
  margin-left: 5px;
}

.book-actions button,
.button-helpful {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin: 5px;
  border: 2px solid #778899; /* Match this to the border color you want */
}




.button-helpful {
  background-color: #808080;
  color: white;
  border: 2px solid #808080;
  margin-top: 15px;
}



.button-helpful:hover {
  background-color: #778899;
}

.button-helpful:disabled {
  background-color: #e0e0e0;
  color: #888888;
  border: 2px solid #e0e0e0;
  margin-top: 10px;
  cursor: not-allowed;
  pointer-events: none;
}

.helpfulText {
  color: #777 !important;
  font-size: 14px !important;
  margin-top: 10px !important;
}

.review-username {
  font-size: 1.1rem;
  font-weight: bold;
  color: #d52513;
  text-decoration: none;
}

.review-username:hover {
  color: #a5281b;
  text-decoration: underline;
}

.review-date {
    font-size: 14px !important;
    color: #666666 !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

.review-item p {
    white-space: pre-wrap;
  }

  .mini_void {
    height: 1.5vh;
  }

  .user-review-pic {
    width: auto;
    height: 63px;
    border-radius: 10%;
    margin-right: 20px;
    margin-top: 3px;
    margin-left: 17px;
    
  }