/* Container for Completed component */
.completed-container {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 0 auto;
}

/* Loading and Error states */
.completed-container.loading,
.completed-container.error {
  text-align: center;
  font-size: 18px;
  color: #888888;
}

/* Header styles */
.completed-container h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
  text-align: center;
}

/* Message for no items */
.no-items-message {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #f9f9f9;
  text-align: center;
}

/* Flex container for completed items */
.completed-items-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/* Completed item styles */
.completed-book-item {
  flex: 1 1 calc(33.333% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  padding: 10px;
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.completed-book-item:hover {
  transform: scale(1.05);
}

.completed-book-item h3 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333333;
}

.completed-book-item p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555555;
}

/* Book cover styles */
.book-cover-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.book-cover {
  width: 100px;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.book-cover-placeholder {
  width: 100px;
  height: 150px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  font-size: 14px;
}

/* Button container styles */
.button-container {
  display: flex;
  justify-content: center;
}

.button-container button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin: 5px;
}

.remove-button {
  background-color: #d13525;
  color: white;
  border: none;
}

.remove-button:hover {
  background-color: #a5281b;
}

.add-review-button {
  background-color: white;
  color: #d13525;
  border: 2px solid #d13525;
}

.add-review-button:hover {
  background-color: #f2f2f2;
  color: #a5281b;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.rating-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.star {
  font-size: 22px;
  color: #ddd;
  cursor: pointer;
}

.star-filled {
  color: #eac784;
  font-size: 22px;
}

.review-title-container {
  position: relative;
  margin-bottom: 10px; /* Adjust as needed */
}

.review-title {
  width: 100%;
  height: 20px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-bottom: 12px;
  
}

.character-count-title {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust as needed */
  transform: translateY(-50%);
  color: #777;
  font-size: 14px;
}

.character-count-title2 {
  position: absolute;
  right: 20px; /* Adjust as needed */
  transform: translateY(-50%);
  color: #777;
  font-size: 14px;
  margin-bottom: 10px;
}

.review-text {
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.character-count {
  text-align: right;
  color: #777;
  font-size: 14px;
}

.submit-review-btn {
  background-color: #d13525;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

/* Media Queries */
@media (max-width: 1200px) {
  .completed-book-item {
    flex: 1 1 calc(33.333% - 20px);
  }
}

@media (max-width: 992px) {
  .completed-book-item {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .completed-book-item {
    flex: 1 1 100%;
  }

  .completed-container {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .completed-book-item h3 {
    font-size: 18px;
  }

  .completed-book-item p {
    font-size: 14px;
  }
}

.sort-controls {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  margin: 20px 0; /* Add margin for spacing */
}

.sort-controls label {
  font-weight: bold;
  margin-bottom: 10px; /* Space between label and select */
  color: #555;
}

.sort-controls select {
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 15px;
}

.completed-book-item h3 a {
  /* Original title styling */
  color: #333; /* Change this to match your original title color */
  text-decoration: none; /* Remove underline if it's there by default */
  font-size: 1.2em; /* Adjust font size as needed */
  font-weight: bold; /* Adjust font weight as needed */
  transition: opacity 0.3s ease; /* Smooth transition for opacity change */
}

.completed-book-item h3 a:hover {
  color: #d13525
}

.review-text, .review-title {
  font-family: 'Ariel', sans-serif;
  font-size: 16px; /* Ensure consistency */
  line-height: 1.5; /* Adjust line height as needed */
}
