/* Align with CompletedTotals */
.wishlist-totals-container {
    margin-top: 20px;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.wishlist-totals-container.loading,
.wishlist-totals-container.error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 1.2rem;
    color: #555555;
}

.wishlist-totals-container.error {
    color: #ff6347; /* Tomato */
}

.wishlist-totals-container h2 {
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333333;
}

.timeframe-filters {
    margin-bottom: 20px;
}

.timeframe-filters button {
    margin-right: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #f0f0f0;
    cursor: pointer;
    font-size: 1rem;
}

.timeframe-filters button.active {
    background-color: #d13525 !important; /* Red */
    color: white !important;
}

.timeframe-filters button:hover {
    background-color: #d0d0d0;
}

.wishlist-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.wishlist-table th,
.wishlist-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
}

.wishlist-table td:nth-child(1),
.wishlist-table th:nth-child(1) {
    padding-left: 25px; /* Adjust as needed */
}

.wishlist-table th {
    background-color: #f5f5f5;
    font-weight: bold;
    color: #555555;
}

.wishlist-table tbody tr:nth-child(even) {
    background-color: #fafafa;
}

.wishlist-table tbody tr:hover {
    background-color: #f0f0f0;
}

@media screen and (max-width: 768px) {
    .wishlist-table {
        box-shadow: none;
        border-radius: 0;
        
    }

.wishlist-table img.book-cover {
      height: 85px;
      width: auto;
  }

    .wishlist-table th,
    .wishlist-table td {
        padding: 10px;
    }

    .wishlist-totals-container {
        padding: 10px;
    }
}

.wishlist-table a {
    color: #d13525;
    text-decoration: none;
}

.wishlist-table a:hover {
    color: #a5281b;
    text-decoration: underline;
}

.big_void {
    height: 20vh; /* Adjust this value as needed */
}

